<template>
  <div class="content">
    <Page class="main">
      <div class="contentindex">
        <div class="settlement_left">
          <div class="w12 product-list-box" v-loading="productListLoading">
            <!-- 商品列表 -->
            <product-list :list="productList" />
            <!-- 优惠卷列表 -->
            <coupon-list
              ref="couponRef"
              :more-coupon="moreCoupon"
              :product-id-list="productIdList"
              :productList="productList"
              @updataCouponNum="updataCouponNum"
              @handle-coupon="handleCoupon"
            />
          </div>
        </div>

        <div class="settlement_right">
          <addressView @syncDefaultAddress="syncDefaultAddress"></addressView>
          <div class="coupons-box rowBC" @click="showCouponList">
            <div>Coupons</div>
            <div v-if="couponsNum > 0" class="num">{{ couponsNum }} Coupons Available</div>
          </div>
          <div class="settlement_title">
            <span class="py-1">{{ $t('Order Summary') }}</span>
          </div>
          <div class="settlement_right_body columnSC">
            <div class="settlement_right_item">
              <span class="key">{{ $t('Items') }}:</span>
              <b class="value">{{ quantity }}</b>
            </div>
            <div class="settlement_right_item">
              <span class="key">{{ $t('Subtotal') }}:</span>
              <b class="value">{{ JLTools.forPrice(subtotal) }}</b>
            </div>
            <div class="settlement_right_item down" @click="showCouponList">
              <div class="rowSC">
                <span class="key">{{ $t('Coupon') }}:</span>
                <img class="down_icon" src="~img/down_icon.svg" :class="moreCoupon ? 'up_icon' : 'down_icon'" />
              </div>
              <div>
                <b class="value">-{{ JLTools.format_number(coupon) }}</b>
              </div>
            </div>
            <div class="settlement_right_item">
              <span class="key">{{ $t('Shipping fee') }}:</span>
              <b class="value">+{{ JLTools.format_number(freight) }}</b>
            </div>
            <div class="settlement_right_item">
              <span class="key">Total Amount:</span>
              <b class="value totle">{{ JLTools.forPrice(total) }}</b>
            </div>
          </div>
          <div class="settlement_title">
            <span class="py-15px">{{ $t('order.PaymentSelection') }}</span>
          </div>
          <form ref="paymentSelectionForm">
            <div class="settlement_right_body payment-selection-section">
              <div v-for="(item, index) in handlePaymentList()" :key="index" class="payment-selectio-box rowSC" @click.stop="selectPayment(item.type)">
                <div class="payment-selection" :class="paymentSelection === item.type ? 'payment-selection-checked' : ''"></div>
                <div class="payment-selection-icon-box rowCC">
                  <img class="payment-selection-icon" :src="item.url" :alt="item.btnName" :style="{ width: `${item.width}px` }" />
                </div>
                <span class="payment-selection-text">{{ item.btnName }}</span>
              </div>
            </div>
          </form>
          <div class="settlement_right_body" style="border: 0px">
            <el-button class="place_order_btn" :loading="loading" @click="placeOrderHandler" style="border-radius: 50px">
              {{ $t('order.placeOrder') }}
            </el-button>
          </div>
          <div class="settlement_right_footer">
            {{ $t(`heier.privacyPolicy`) }}
          </div>
        </div>
      </div>
    </Page>
  </div>
</template>

<script>
  import { defineComponent, getCurrentInstance, onMounted, ref, reactive, toRefs, toRaw, watchEffect, nextTick } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import addressView from './compontent/addressView'
  import productList from './compontent/product-list'
  import couponList from './compontent/coupon-list'
  import { haveCommonElements, findIndexOfCommonElement } from '@/util/func'
  import Page from '@/components/Page'
  import JLTools from '@/util/JLTools'
  import { Decimal } from 'decimal.js'
  import { ElMessage } from 'element-plus'
  import api from '@/api'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  import php from '@/php/index'

  export default defineComponent({
    components: { Page, addressView, productList, couponList },
    setup() {
      //vue上下文
      const { proxy } = getCurrentInstance()
      const router = useRouter()
      const route = useRoute()
      const couponRef = ref()
      //数据定义
      const data = reactive({
        productListLoading: false,
        loading: false,
        specsList: [],
        //结算商品列表
        productList: [],
        //结算商品ids列表
        productIdList: [],
        //显示更多优惠券
        moreCoupon: false,
        //显示更多支付方式
        morePayType: false,
        //Modal框的地址信息
        addressData: {
          consignee: ''
        },
        //默认的地址
        defaultAddress: {},
        //选中使用的券
        useCoupon: {},
        couponsNum: 0 // 优惠卷数量
      })

      // 订单信息
      const orderData = reactive({
        quantity: 0, // 数量
        subtotal: 0, // 商品价格
        coupon: 0, // 优惠卷
        freight: 0, // 运费
        total: 0 // 合计
      })

      let paymentList = [
        { type: 'COD', typeName: 'cash-on-delivery', name: '货到付款', url: require('@/assets/images/cash-on-delivery-icon.svg'), btnName: 'Cash On Delivery', width: 28 },
        { type: 'EASY_PAISA', typeName: 'easy-paisa', name: 'EasyPaisa', url: require('@/assets/images/easy-paisa-icon.svg'), btnName: 'EasyPaisa' },
        { type: 'CARD', typeName: 'card', name: '卡支付', url: require('@/assets/images/card-icon.svg'), btnName: 'Credit/Debit Card' },
        // { type: 'BANK_TRANSFER', typeName: 'bank-transfer', name: '转账', url: require('@/assets/images/bank-transfer-icon.png'), btnName: 'Bank Transfer' },
        { type: 'JAZZ_CASH', typeName: 'jazzcash', name: 'jazzcash', url: 'https://cdn.pricoo.pk/75a4a6f2.png', btnName: 'JazzCash', width: 28 }
      ]
      const paymentSelection = ref('COD')

      const handlePaymentList = () => {
        // "development" 'production'
        // if (process.env.VUE_APP_MODE === 'production') {
        //   paymentList = paymentList.slice(0, 1)
        // }

        return paymentList
      }

      onMounted(() => {
        if (JLTools.isNull(route.query)) {
          return false
        }
        if (!userStore.userInfo.userId) {
          return proxy.$router.push({
            path: '/login'
          })
        }
        let dataJO = JSON.parse(route.query.List)
        init(dataJO)
      })

      // 同步更改默认地址查询运费
      const syncDefaultAddress = (address, type) => {
        data.defaultAddress = address
        console.log('运费查询', data.defaultAddress)
        if (type !== 'init') {
          freightSearch()
        }
      }

      // 运费查询
      const freightSearch = () => {
        let param = []
        data.productList.forEach((item) => {
          param.push({
            specId: Number(item.specId),
            amount: Number(item.purchaseNum),
            // userProvince: data.defaultAddress.province,
            // userCity: data.defaultAddress.city,
            // 100669
            areaNo: data.defaultAddress.areaNo
          })
        })
        // console.log('freight param', param, data.defaultAddress.city)
        api.product.freightSearch(param).then((res) => {
          let canSend = true
          if (!res.length) return
          // fix freight 将每个商品的运费添加到productList中
          data.productList.forEach((item) => {
            res.forEach((resItem) => {
              if (item.specId === resItem.specId) {
                item.freight = resItem.freight
                if (item.freight === null) {
                  canSend = false
                }
              }
            })
          })
          if (canSend) {
            orderData.freight = data.productList.reduce((acc, cur) => acc + cur.freight, 0)
          } else {
            console.log('超出配送范围')
            // ElMessage({
            // 	message: "Your shipping address is not within the shipping range",
            // 	type: "error",
            // });
            orderData.freight = 0
          }
        })
      }

      watchEffect(() => {
        // console.log(orderData.quantity, orderData.freight, orderData.coupon, '订单金额')
        orderData.total = new Decimal(orderData.subtotal).add(new Decimal(orderData.freight)).sub(new Decimal(orderData.coupon)).toNumber()
        orderData.total = orderData.total < 0 ? 0 : orderData.total
      })

      // 初始化
      const init = (dataJO) => {
        getSpecsDetailsList(dataJO)
        // loadProductList(dataJO)
      }

      const getSpecsDetailsList = (dataJO) => {
        if (data.productListLoading) return
        data.productListLoading = true
        api.product
          .getSpecsDetailsList(dataJO)
          .then((res) => {
            console.log(res, '规格详情')
            // 初始化计算金额
            loadProductList(res)
          })
          .finally(() => {
            data.productListLoading = false
          })
      }

      // 加载结算的商品数据
      const loadProductList = async (dataList) => {
        if (!dataList || dataList.length === 0) return
        data.productList = dataList.flatMap((productItem) => {
          return productItem.spec.map((specData) => {
            return {
              ...specData,
              ...{
                productId: productItem.productId,
                productName: productItem.productName,
                productCoverImg: productItem.productCoverImg,
                presell: productItem.presell,
                purchaseNum: specData.purchaseNum || '',
                discountPrice: specData.discountPrice || '',
                freight: 0, // 运费金额
                skuArr: [
                  { key: specData.firstAttrName, value: specData.firstAttrValue },
                  { key: specData.secondAttrName, value: specData.secondAttrValue },
                  { key: specData.thirdAttrName, value: specData.thirdAttrValue }
                ].filter((skuItem) => skuItem.key && skuItem.value)
              }
            }
          })
        })
        data.productIdList = data.productList.map((item) => item.productId)
        // console.log(data.productList, ' data.productList')
        // 初始化计算金额
        initCalculate(data.productList)
        // 查询运费
        freightSearch()

        // paymentMethods()
      }

      // 查询支付方式
      const paymentMethods = () => {
        const params = {
          specIds: Object.values(data.productIdList).join(','),
          bulkOrder: false
        }
        api.pay.paymentMethods(params).then((res) => {
          console.log(res)
        })
      }

      // 初始化计算应付金额
      const initCalculate = (productList) => {
        const productCount = productList.reduce((total, product) => total + product.purchaseNum, 0)
        const subtotal = productList.reduce((total, product) => total.add(new Decimal(product.specAmount).mul(product.purchaseNum)), new Decimal(0))
        orderData.quantity = productCount
        orderData.subtotal = subtotal.toNumber()
        orderData.total = new Decimal(orderData.subtotal).sub(new Decimal(orderData.coupon)).toNumber()
        console.log(orderData.total, orderData.freight, 'total')
      }

      // 显示更多优惠卷
      const showCouponList = async () => {
        await nextTick()
        data.moreCoupon = !data.moreCoupon
        couponRef.value.togglePopUp(true)
      }

      const updataCouponNum = (num) => {
        console.log('更新updataCouponNum')
        data.couponsNum = num
      }

      // 处理优惠卷减免金额
      const handleCoupon = (coupon) => {
        data.useCoupon = coupon
        console.log(data.useCoupon, 'data.useCoupon')
        let curIndex = 0 // 使用优惠卷商品下标
        data.productList.forEach((product) => {
          product.discountPrice = 0
        })

        if (data.useCoupon.id) {
          if (data.useCoupon?.suitableProduct === 'ASSIGNABLED' && data.useCoupon?.productIds) {
            // 处理特定范围逻辑
            const couponProductList = coupon.productIds.split(',')
            //指定商品可用
            curIndex = findIndexOfCommonElement(toRaw(data.productIdList), couponProductList)
          }
          if (data.useCoupon.couponType === 'PRODUCT_DISCOUNT') {
            data.productList[curIndex].discountPrice = data.useCoupon.reduceAmount
              ? data.useCoupon.reduceAmount
              : data.productList[curIndex].specAmount * data.productList[curIndex].purchaseNum * data.useCoupon.discountRate
            // 商品优惠卷
          } else if (data.useCoupon.couponType === 'REDUCED_FREIGHT') {
            // data.productList[curIndex].discountFreightPrice = data.useCoupon.reduceAmount
            //   ? data.useCoupon.reduceAmount
            //   : data.productList[curIndex].freight * data.useCoupon.discountRate
            data.productList[curIndex].discountPrice = data.productList[curIndex].freight
            // 运费优惠卷
          }
        }

        // orderData.coupon = data.productList.reduce((total, product) => total + product.discountPrice, 0)
        orderData.coupon = Math.round(data.productList.reduce((total, product) => total + product.discountPrice, 0))
      }

      // 处理创建订单参数信息
      const handlePlaceOrderData = (list) => {
        // 使用 reduce 方法对产品列表进行分组
        const groupedSpecArray = list.reduce((result, product) => {
          // 查找结果数组中是否已存在具有相同 productId 的项目
          const existingItem = result.find((item) => item.productId === product.productId)

          // 如果找到了现有项目，则将当前产品的规格信息推入该项目的 orderSpecs 数组中
          if (existingItem) {
            existingItem.orderSpecs.push({
              amount: product.specAmount,
              purchaseNum: product.purchaseNum,
              skuNo: product.skuNo,
              specId: product.specId,
              specName: product.specName,
              costAmount: product.costAmount,
              userId: userStore.userInfo.userId
            })
          } else {
            result.push({
              userId: userStore.userInfo.userId, // 当前用户的 ID
              nickname: userStore.userInfo.nickname, // 当前用户的昵称
              channel: paymentSelection.value, // 支付渠道
              amount: data.Totle, // 总金额
              costAmount: product.costAmount, // 当前产品的成本金额
              presell: product.presell, // 当前产品是否为预售
              productId: product.productId, // 当前产品的 ID
              productName: product.productName, // 当前产品的名称
              productCoverImg: product.productCoverImg, // 当前产品的封面图片
              consignee: data.defaultAddress.consignee || '', // 收件人姓名，若无则为空
              mobile: data.defaultAddress.mobile, // 收件人手机号
              address: data.defaultAddress.address, // 收件人地址
              areaNo: data.defaultAddress.areaNo, // 区域编号
              city: data.defaultAddress.city, // 城市
              province: data.defaultAddress.province, // 省份
              couponId: data.useCoupon?.id || '', // 优惠券 ID，若无则为空
              reducedAmount: data.useCoupon?.reduceAmount || '', // 优惠金额，若无则为空
              couponRecordId: data.useCoupon?.couponIssueId || '', // 优惠券记录 ID，若无则为空
              zipCode: '100111', // 邮政编码（固定值）
              district: '', // 区域（未填）
              orderSpecs: [
                // 当前产品的规格数组
                {
                  amount: product.specAmount, // 当前产品的规格数量
                  purchaseNum: product.purchaseNum, // 当前产品的购买数量
                  skuNo: product.skuNo, // 当前产品的 SKU 编号
                  specId: product.specId, // 当前产品的规格 ID
                  specName: product.specName, // 当前产品的规格名称
                  costAmount: product.costAmount, // 当前产品的成本金额
                  userId: userStore.userInfo.userId // 当前用户的 ID
                }
              ]
            })
          }

          return result
        }, [])

        // console.log(groupedSpecArray, 'deepNestedSpecArray')
        return groupedSpecArray
      }

      const selectPayment = (paymentType) => {
        console.log(paymentType, 'paymentType')
        paymentSelection.value = paymentType
      }

      // 创建订单预处理
      const placeOrderHandler = () => {
        const productList = data.productList

        if (proxy.JLTools.isNull(userStore.userInfo.userId)) {
          return proxy.$router.push({
            path: '/login'
          })
        }

        if (productList.length === 0) {
          // 商品列表不可为空
          proxy.$toast(proxy.$t('No Item'))
          return false
        }

        if (!data.defaultAddress.city) {
          // 默认配送地址不可为空
          proxy.$toast(proxy.$t('No shipping address'))
          return false
        }

        if (!data.defaultAddress.areaNo) {
          // 默认配送地址不可为空
          // proxy.$Message.confirm({
          //   title: proxy.$t('Notice'),
          //   msg: proxy.$t('Your shipping address is not within the shipping range')
          // })
          proxy.$toast(proxy.$t('Your shipping address is not within the shipping range'))
          return false
        }
        if ((orderData.quantity > 3 || orderData.subtotal > 300000) && paymentSelection.value === 'COD') {
          // console.log('触发规则：当为货到付款的时候，商品数量不能超过3且金额小于300000')
          const codContent = `Cash on delivery orders: Max 3 items, payment capped at Rs. 300,000.`
          proxy.$Message.confirm({
            title: 'warning',
            msg: `${codContent}`,
            isCancel: false
          })
          return
        }
        if (data.loading) return
        data.loading = true

        const orderList = handlePlaceOrderData(productList)
        console.log('orderList:', orderList)
        createdOrder(orderList)
      }

      // 创建订单 orderList=>订单列表
      const createdOrder = (orderList) => {
        api.order
          .createOrder(orderList, data.useCoupon.couponIssueId)
          .then((res) => {
            console.log('查看createdOrder接口返回值: ', res)
            if (!JLTools.isNull(res) && !JLTools.isNull(res.mainOrderNo)) {
              orderPay(res)
            } else {
              proxy.$toast(proxy.$t('Request exception, Order creation failure'))
            }
          })
          .catch((error) => {
            console.log('说明走了catch:', error)
            let title = proxy.$t('Notice')
            if (error.status === 400) {
              proxy.$Message.confirm({
                title: title,
                msg: error.title ? `${error.title}` : 'Your shipping address is not within the shipping range'
              })
              // const msg = error.title ? `${error.title}` : 'Your shipping address is not within the shipping range'
              // proxy.$toast(proxy.$t(msg))
            }
          })
          .finally(() => {
            data.loading = false
          })
      }

      // 提交订单 orderList=>后端返回的订单列表
      const orderPay = (orderList) => {
        console.log('提交订单', orderList)
        switch (paymentSelection.value) {
          case 'COD':
            // 货到付款|转账
            handleCashOnDelivery(orderList)
            break
          case 'BANK_TRANSFER':
            goSuccessful(orderList)
            break
          default:
            // 其他付款方式 /easypaisa /card|Credit|debit card
            router.push({ name: 'OTHERPAYMENT', query: { orderId: `${orderList.mainOrderNo}`, paymentType: `${paymentSelection.value}` } })
            break
        }
      }

      // 货到付款
      const handleCashOnDelivery = (orderList) => {
        console.log(orderList.purchaseOrderList, paymentSelection.value, 'orderId')
        api.pay
          .getPayments({
            orderNo: orderList.mainOrderNo,
            paymentStatus: true
          })
          .then((res) => {
            goSuccessful(orderList)
            console.log(res, '货到付款成功')
          })
          .catch((err) => {
            proxy.$toast(err)
          })
        // api.pay
        //   .getPayments({
        //     orderNo: orderList.mainOrderNo,
        //     paymentStatus: true
        //   })
        //   .then((res) => {
        //     const params = {
        //       orderId: orderList.mainOrderNo,
        //       totalAmount: orderData.total,
        //       customer_mobile_no: data.defaultAddress.mobile
        //     }
        //     php.post('services/sms/send/notify/order-created', params)
        //       .then((response) => console.log(response))
        //       .catch((error) => console.error(error))
        //     goSuccessful(orderList)
        //     console.log(res, '货到付款成功')
        //   })
        //   .catch((err) => {
        //     proxy.$toast(err)
        //   })
      }

      const goSuccessful = (orderList) => {
        router.replace({
          name: 'successful',
          query: {
            orderId: `${orderList.mainOrderNo}`,
            paymentType: `${paymentSelection.value}`
          }
        })
      }

      return {
        couponRef,
        ...toRefs(data),
        ...toRefs(orderData),
        updataCouponNum,
        handlePaymentList,
        paymentSelection,
        selectPayment,
        placeOrderHandler,
        showCouponList,
        loadProductList,
        handleCoupon,
        syncDefaultAddress,
        orderPay
      }
    }
  })
</script>

<style scoped lang="less">
  .contentindex {
    margin: 0 auto;
    display: flex;
    background-color: #eff0f5;
    padding: 20px;
  }

  .settlement_left {
    width: 830px;
    padding-right: 20px;
    .product-list-box {
      min-height: 400px;
    }
  }

  .settlement_right {
    float: right;
    width: 400px;
    min-height: 500px;
    background: #fff;
    padding: 0 15px 15px;
    border-radius: 5px;
    .coupons-box {
      padding: 10px 12px;
      font-size: 16px;
      font-weight: 500;
      background: rgba(236, 236, 236, 0.6);
      border-radius: 4px;
      cursor: pointer;
      .num {
        color: var(--btnBgColor);
      }
    }
  }

  .settlement_right_body {
    width: 100%;
    border-bottom: 1px solid #e4e4e4;
    box-sizing: border-box;
  }

  .settlement_right_item {
    width: 100%;
    font-size: 14px;
    line-height: 34px;
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  .settlement_right_item b.totle {
    color: var(--PicColor);
    font-size: 16px;
    font-weight: bold;
  }

  .settlement_right_item.down {
    cursor: pointer;
    .down_icon {
      width: 15px;
      height: 15px;
      margin: 10px 0 10px 5px;
    }
    .up_icon {
      transform: rotate(180deg);
      transition: transform 0.3s;
    }
  }

  .settlement_right_body .place_order_btn {
    float: left;
    width: 100%;
    height: 40px;
    background: var(--btnBgColor);
    color: #fff;
    font-size: 16px;
  }

  .settlement_right_body .place_order_btn:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  .settlement_right_footer {
    float: left;
    width: 100%;
    color: #999;
    margin-top: 10px;
    text-align: center;
  }

  .settlement_right_footer a {
    color: var(--btnBgColor);
  }

  .settlement_right_footer a:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .cart_quantity_item {
    float: left;
    background: #fff;
  }

  .cart_quantity_item img {
    float: left;
    padding: 5px;
    width: 26px;
    height: 26px;
    text-align: center;
    border: 1px solid #ccc !important;
  }

  .cart_quantity_item img:hover {
    cursor: pointer;
    background: #eee;
  }

  .cart_quantity_item img.disable {
    cursor: not-allowed;
    background: #f5f5f5;
  }

  .cart_quantity_item input {
    float: left;
    text-align: center;
    width: 40px;
    height: 26px;
    border: 1px solid #ccc;
    border-left: 0px;
    border-right: 0px;
  }

  .address_none {
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #ccc;
  }

  .address_list {
    word-break: break-all;
    width: calc(100% - 70px);
  }

  .payment-selection-section {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 10px;
    padding-bottom: 20px;
    border-bottom: none !important;
    .payment-selectio-box {
      min-height: 20px;
      .payment-selection {
        position: relative;
        display: inline-block;
        width: 16px; /* 调整大小 */
        height: 16px; /* 调整大小 */
        border: 1px solid #000; /* 设置边框 */
        border-radius: 50%; /* 使其变成圆形 */
        margin-right: 2px; /* 调整与文本的间距 */
      }

      .payment-selection-checked:before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background-color: #222;
      }
      .payment-selection-icon-box {
        width: 35px;
        margin: 0 5px;
        .payment-selection-icon {
          width: 16px;
          vertical-align: middle;
        }
      }
      .payment-selection-text {
        vertical-align: middle;
        font-weight: 600;
        font-size: 14px;
        color: #222;
      }
    }
    label {
      display: flex;
      align-content: center;
      align-items: center;
    }
  }

  b {
    font-weight: 700 !important;
  }

  /* 使得前缀和后面的数值之间有一定空隙并对齐 */
  /* 清除浮动 */
  .modal-icon {
    width: 16px;
    height: 16px;
  }

  .settlement_title span {
    width: 100%;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
  }
</style>
