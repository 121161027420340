<script setup>
  import couponCard from '@/views/userinfo/coupon/compontent/coupon-card'
  import { defineComponent, getCurrentInstance, onMounted, ref, reactive, toRefs, toRaw, watch, defineExpose } from 'vue'
  import { haveCommonElements, findIndexOfCommonElement } from '@/util/func'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const { proxy } = getCurrentInstance()

  const $emit = defineEmits(['handleCoupon', 'updataCouponNum'])
  const props = defineProps({
    moreCoupon: {
      type: Boolean,
      default: true
    },
    // 商品列表
    productList: {
      type: Array
    },
    // 商品id列表
    productIdList: {
      type: Array
    }
  })
  const data = reactive({
    loading: false,
    //用户所有券
    allCouponList: [],
    //当前可使用券
    couponList: [],
    //选中使用的券
    useCoupon: {},
    productIdList: [],
    dialogVisible: false
  })

  watch(
    () => props.productList,
    (newValue) => {
      // console.log(toRaw(newValue), newValue.length, 'newValue')
      if (newValue.length) {
        initCouponList()
      }
    }
  )

  // 加载用户优惠券并筛选当前商品可用优惠券
  const initCouponList = () => {
    data.loading = true
    // 计算specAmount和purchaseNum乘积的最小值
    // console.log(props.productList, 'props.productList')
    let minProduct = props.productList.reduce((min, item) => Math.min(item.purchaseNum * item.specAmount, min), props.productList[0].purchaseNum * props.productList[0].specAmount)
    // 计算specAmount和purchaseNum乘积的最大值
    let maxProduct = props.productList.reduce((max, item) => Math.max(item.purchaseNum * item.specAmount, max), props.productList[0].purchaseNum * props.productList[0].specAmount)
    // console.log('乘积最小值: ' + minProduct)
    // console.log('乘积最大值: ' + maxProduct)
    api.coupon
      .getCouponByUseridReq(data.userInfo.userId)
      .then((res) => {
        if (JLTools.isNull(res)) {
          data.loading = false
          return false
        }
        const list = res.filter((item) => {
          return item.coupon.reduceAmount < minProduct && item.coupon.spendAmount < minProduct
        })
        couponfiltr(list)                                                                   
        data.loading = false
      })
      .catch((err) => {
        let title = proxy.$t('Error')
        data.loading = false
        console.log(err, title, '查询可使用优惠卷error')
      })
  }

  // 优惠卷列表过滤 =>suitableProduct
  const couponfiltr = (res) => {
    // console.log(res, 12312)
    data.allCouponList = res
    // console.log(data.allCouponList, 'data.allCouponList') // 输出提取出的属性值集合
    // 获取商品的最小采购数量
    // const minLime = props.productList.reduce((min, product) => {
    //   return product.purchaseNum < min ? product.purchaseNum : min
    // }, props.productList[0].purchaseNum)
    // 筛选出 couponType 为 'PRODUCT_DISCOUNT' 或 'REDUCED_FREIGHT' 的优惠卷
    const couponTypeList = ['PRODUCT_DISCOUNT', 'REDUCED_FREIGHT']
    const allCouponList = res.filter((item) => {
      return couponTypeList.includes(item.coupon.couponType)
      // && item.coupon.limitNum >= minLime
    })
    // console.log(allCouponList, toRaw(props.productIdList), 'data.productList') // 输出提取出的属性值集合

    allCouponList.forEach((couponItem) => {
      const coupon = couponItem.coupon
      coupon.couponIssueId = couponItem.id
      const couponProductList = coupon.productIds ? coupon.productIds.split(',') : []
      // 所有商品可用
      if ('ALLABLED' === coupon.suitableProduct) {
        data.couponList.push(coupon)
      }

      //指定商品可用
      if ('ASSIGNABLED' === coupon.suitableProduct) {
        if (findIndexOfCommonElement(toRaw(props.productIdList), couponProductList) >= 0) {
          // 指定商品可用包含任一商品
          data.couponList.push(coupon)
        }
      }
      //  指定商品不可用
      if ('ASSIGNDISABLED' === coupon.suitableProduct) {
        if (!haveCommonElements(toRaw(props.productIdList), couponProductList)) {
          // 指定商品不可用不包含任一商品
          data.couponList.push(coupon)
        }
      }
    })

    $emit('updataCouponNum', data.couponList.length)
    // productList.filter
    console.log(data.couponList, data.couponList.length, 'data.couponList 可使用优惠卷')
  }

  // 选择使用券
  const changeCoupon2 = (index) => {
    const selectedCoupon = data.couponList[index]
    selectedCoupon.chooseCoupon = !selectedCoupon.chooseCoupon
    data.couponList.forEach((coupon, i) => {
      if (i !== index) {
        coupon.chooseCoupon = false
      }
    })

    data.useCoupon = selectedCoupon.chooseCoupon ? selectedCoupon : {}
    // console.log(data.useCoupon, 'data.useCoupon')
    // handleCoupon()
    $emit('handleCoupon', data.useCoupon)
  }

  // 选择使用券
  const changeCoupon = (coupon) => {
    // console.log(coupon, 'coupon')
    $emit('handleCoupon', coupon)
    togglePopUp()
  }

  // 切换弹窗隐藏
  const togglePopUp = (type) => {
    // console.log(type, 'type')
    data.dialogVisible = type ? true : false
  }

  onMounted(() => {
    data.userInfo = userStore.userInfo
  })

  defineExpose({
    togglePopUp
  })
</script>

<template>
  <el-dialog v-model="data.dialogVisible" title="Select Coupon" width="920" center class="dialog-box">
    <div class="coupon-list-box" v-loading="data.loading">
      <div class="payment_method innerbox rowSC" v-if="data.couponList.length > 0">
        <div class="payment_method_item rowSC" :class="{ xuan: coupon.chooseCoupon }" v-for="(coupon, index) of data.couponList" :key="index">
          <template v-if="coupon">
            <couponCard :item="coupon" :coupon="coupon.name ? coupon : coupon.coupon" type="buyDialog" @clickCard="changeCoupon"></couponCard>
          </template>
        </div>
      </div>
      <div v-else class="rowCC no-list-box">
        <div>{{ $t('No More Coupon') }}</div>
      </div>
    </div>
  </el-dialog>
</template>

<style scoped>
  .coupon-list-box {
    width: 100%;
    min-height: 200px;
    .no-list-box {
      min-height: 200px;
    }
  }
  .payment_method {
    max-height: 380px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    overflow: hidden;
    overflow-y: auto;
  }

  .payment_method_item {
    font-size: 12px;
  }

  .payment_method_item .checkbox {
    width: 14px;
    height: 14px;
    border: 1px solid #aaa;
    cursor: pointer;
    margin-right: 5px;
  }

  .payment_method_item:hover {
    cursor: pointer;
    /* background: #f5f5f5; */
  }

  .payment_method_item.xuan .checkbox {
    width: 14px;
    height: 14px;
    border: 1px solid #aaa;
    cursor: pointer;
    background: var(--btnBgColor);
    border: 1px solid var(--btnBgColor);
  }

  .payment_method_item.xuan .checkbox img {
    display: block;
  }

  .payment_method_item.xuan {
    color: var(--btnBgColor);
  }
</style>
