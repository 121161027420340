<template>
  <div class="city-box rowBC">
    <ElSelect placeholder="Province" v-model="selectedProvince" @change="choosePro" class="option-box">
      <el-option :key="index" v-for="(provItem, index) in proviceArr" :value="provItem.no" :label="provItem.name"> </el-option>
    </ElSelect>
    <ElSelect placeholder="City" v-model="selectedCity" @change="chooseCity" filterable class="option-box">
      <el-option :key="index" v-for="(provItem, index) in cityArr" :value="provItem.no" :label="provItem.name"> </el-option>
    </ElSelect>
  </div>
</template>
<script>
  import { onMounted, reactive, toRefs, watch, getCurrentInstance } from 'vue'
  import api from '@/api'
  import JLTools from '@/util/JLTools'
  import useStore from '@/store/pinia'
  const { HsystemStore } = useStore()

  export default {
    props: {
      province: {
        type: String,
        default: ''
      },
      city: {
        type: String,
        default: ''
      },
      district: {
        type: String,
        default: ''
      },
      zipcode: {
        type: String
      }
    },
    setup(props, context) {
      const state = reactive({
        proviceArr: [],
        cityArr: [],
        districtArr: [],
        selectedProvince: props.province || '',
        selectedCity: props.city || '',
        selectedDistrict: props.district || '',
        areaNo: ''
      })
      const { proxy } = getCurrentInstance()
      /**
       * 监听 district 变动，重新渲染数据
       */
      watch(
        () => props.district,
        (newVal, oldVal) => {
          console.log(newVal, oldVal, 'props.district')
          addressInit()
        }
      )

      const addressInit = () => {
        console.log(props.province.city, 'props.province')
        state.selectedProvince = props.province
        state.selectedCity = props.city
        state.selectedDistrict = props.district
        console.log(state, props.city)
        change()
      }

      onMounted(() => {
        getTreeData()
      })

      const getTreeData = () => {
        if (!JLTools.isNull(HsystemStore.addressData)) {
          // console.log('存在addressData')
          state.proviceArr = HsystemStore.addressData
        } else {
          // console.log('重新请求城市地址addressData')
          api.address
            .getAreaTree(9)
            .then((res) => {
              console.log('getAreaTree:', res)
              localStorage.setItem('addressData', JSON.stringify(res.children))
              HsystemStore.setAddressData(JSON.stringify(res.children))
              state.proviceArr = res.children
            })
            .catch((error) => {
              console.log(error)
            })
        }
      }
      const choosePro = (no) => {
        const tmp = state.proviceArr.find((item) => item.no === no)
        console.log(tmp)
        state.selectedProvince = tmp.name
        state.cityArr = tmp.children
        state.selectedCity = ''
      }

      const chooseCity = (no) => {
        const tmp = state.cityArr.find((item) => item.no === no)
        state.selectedCity = tmp.name
        let address = {}
        address.province = state.selectedProvince
        address.city = state.selectedCity
        address.areaNo = tmp.no
        // eslint-disable-next-line vue/custom-event-name-casing
        context.emit('changeAddress', address)
      }

      /**
       * 渲染数据
       */
      const change = async () => {
        await updatePro()
        await updateCity()
        proxy.$forceUpdate()
      }

      const updatePro = () => {
        for (let item of state.proviceArr) {
          if (item.name === state.selectedProvince) {
            item.selected = true
            state.cityArr = item.children
          }
        }
        if (0 === state.cityArr.length) {
          return false
        }
      }

      const updateCity = () => {
        for (let item of state.cityArr) {
          if (item.name === state.selectedCity) {
            item.selected = true
            state.districtArr = item.children
          }
        }
        if (0 === state.districtArr.length) {
          return false
        }
      }

      return {
        ...toRefs(state),
        choosePro,
        chooseCity,
        getTreeData,
        updatePro,
        updateCity,
        addressInit
      }
    }
  }
</script>
<style scoped>
  .city-box {
    width: 100%;
    .option-box {
      width: 50%;
      &:nth-child(2) {
        margin-left: 10px;
      }
    }
  }
</style>
