<script setup>
  import CheckBox from '@/components/CheckBox'
  import Address from '@/components/address/Address'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, defineExpose, nextTick, watch } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore, HsystemStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const $emit = defineEmits(['saveTheAddress'])
  const props = defineProps({
    addressData: {
      type: Object
    },
    modalTitle: {
      type: String,
      default: 'shipping address'
    }
  })

  const addressRef = ref(null)
  const ruleFormRef = ref()
  const data = reactive({
    loading: false,
    addressShow: false,
    addressData: {
      consignee: '',
      mobile: '',
      province: '',
      city: '',
      address: '',
      defAddress: false
    },
    InternationalMobileNumber: '+92'
  })

  const InternationalMobileNumber = [
    {
      value: '+92',
      label: '+92'
    }
  ]

  const rules = reactive({
    consignee: [
      { required: true, message: 'please input your name', trigger: 'blur' },
      { min: 3, max: 15, message: 'Length should be 3 to 15', trigger: 'blur' }
    ],
    mobile: [
      { required: true, message: 'Please enter your mobile number', trigger: 'blur' },
      { pattern: /^(0\d{10}|\d{10})$/, message: 'Invalid mobile phone number', trigger: 'blur' }
    ],
    address: [
      { required: true, message: 'Please enter address', trigger: 'change' },
      { max: 150, message: 'The value contains a maximum of 150 characters', trigger: 'change' }
    ],
    city: [{ required: true, message: 'Please select Area', trigger: 'blur' }]
  })

  const showModal = async () => {
    data.addressData = Object.assign({}, props.addressData)
    // console.log(data.addressData.city, 'data.addressData')
    data.addressShow = true
    await nextTick()
    addressRef.value.addressInit()
  }

  const hideModal = () => {
    data.loading = false
    data.addressShow = false
    ruleFormRef.value.resetFields()
    const addressData = {
      consignee: '',
      mobile: '',
      province: '',
      city: '',
      address: '',
      defAddress: false
    }
  }

  // 是否设置默认地址
  const changeBox = (flag) => {
    data.addressData.defAddress = flag
  }

  // 更改地址信息
  const changeAddress = (address) => {
    data.addressData.province = address.province
    data.addressData.city = address.city
    data.addressData.areaNo = address.areaNo
    // console.log('changeAddress:', data.addressData)
    ruleFormRef.value.validate()
  }

  const submitForm = async (formEl) => {
    await formEl.validate((valid, fields) => {
      if (valid) {
        // console.log('submit!')
        data.loading = true
        $emit('saveTheAddress', data.addressData)
      } else {
        console.log('error submit!', fields)
      }
    })
  }

  defineExpose({
    showModal,
    hideModal
  })
</script>

<template>
  <div>
    <Teleport to="body">
      <div class="addressWrap" v-show="data.addressShow">
        <div class="addressModal">
          <div class="title">
            {{ props.modalTitle }}
          </div>
          <el-form
            ref="ruleFormRef"
            :model="data.addressData"
            :rules="rules"
            label-width="100px"
            label-position="top"
            v-loading="data.loading || JLTools.isNull(HsystemStore.addressData)"
          >
            <div class="rowBC">
              <el-form-item label="Full Name" class="form-item-50" prop="consignee">
                <el-input v-model="data.addressData.consignee" @blur="data.addressData.consignee = $event.target.value.trim()" placeholder="Name" clearable />
              </el-form-item>
              <el-form-item label="Phone" class="form-item-50 ml10" prop="mobile">
                <el-input class="phone-input" v-model="data.addressData.mobile" maxlength="11" placeholder="Phone" clearable>
                  <template #prepend>
                    +92
                    <!-- <el-select v-model="data.InternationalMobileNumber" class="phone-select">
                      <el-option v-for="item in InternationalMobileNumber" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select> -->
                  </template>
                </el-input>
              </el-form-item>
            </div>
            <el-form-item label="Area" prop="city">
              <Address ref="addressRef" @changeAddress="changeAddress" :province="data.addressData.province" :city="data.addressData.city"> </Address>
            </el-form-item>
            <el-form-item label="Address" prop="address">
              <el-input
                type="textarea"
                v-model="data.addressData.address"
                @blur="data.addressData.address = $event.target.value.trim()"
                maxlength="150"
                :autosize="{ minRows: 2, maxRows: 6 }"
                placeholder="Address"
                clearable
              />
            </el-form-item>
            <check-box v-model="data.addressData.defAddress" @modelValue="changeBox">
              <span>{{ $t('Set as default') }}</span>
            </check-box>
            <div class="btn-bo rowCC mt20">
              <el-button class="btn" @click="hideModal">Cancel</el-button>
              <el-button class="btn hr-primary-btn" @click="submitForm(ruleFormRef)">Ok</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<style lang="less" scoped>
  /* phone-input 类下的 el-input__wrapper */
  :deep(.phone-input .el-input__wrapper) {
    border-radius: 0px 10px 10px 0px !important;
  }

  /* phone-select 类下的 el-input__wrapper */
  :deep(.phone-select .el-input__wrapper) {
    border-radius: 0px !important;
  }
  .phone-select {
    width: 80px;
  }
  .addressWrap {
    position: fixed;
    z-index: 2009;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.4);
    .addressModal {
      min-width: 500px;
      min-height: 300px;
      padding: 30px 88px;
      background: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 8px;
      .title {
        margin-bottom: 24px;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #363636;
      }
      .form-item-50 {
        width: 50%;
        .el-input {
          height: 50px;
        }
      }
      .btn {
        width: 180px;
        height: 48px;
        border-radius: 34px;
        font-size: 20px;
        font-weight: 500;
      }
      
      .btn-cancel {
        height: 54px;
        border: 2px solid #222;
        background: #fff;
        color: #222;
      }
    }
  }
</style>
